import React from "react"
import Layout from "../../components/Layout"
import useTeamData from "../../static_queries/useTeamData"
import LargeCard from "../../components/LargeCard"
import Tabs from "antd/lib/tabs"
import "antd/lib/tabs/style/index.css"
import BlogList from "../../components/BlogList"
const { TabPane } = Tabs

export default function Team() {
  const { frontmatter } = useTeamData()

  return (
    <Layout page="home" title={frontmatter.title}>
      <Tabs type="card">
        <TabPane tab="Our Team" key="1">
          <LargeCard cards={frontmatter.card}/>
        </TabPane>
        <TabPane tab="Recent News" key="2">
          <BlogList/>
        </TabPane>
      </Tabs>
    </Layout>
  )
}
